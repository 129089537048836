import PropTypes from "prop-types";
import Layout from "@layout";
import SEO from "@components/seo";
import { graphql } from "gatsby";
import PageBreadcrumb from "../components/pagebreadcrumb";
import TestimonialArea from "../container/about-us/testimonial";
import TeamArea from "../container/about-us/team";
import WhyChooseUsArea from "../container/about-us/whay-choose-us";
import AboutMoreArea from "../container/about-us/about-more";
import { normalizedData } from "@utils/functions";
import BlogRightSidebar from "../container/blog/blog-right-sidebar";
import Herosection from "../components/about/herosection";
import AboutCard from "../container/about-us1/card";
import AboutteamCard from "../container/about-us1/aboutus-team";
import aboutus from "../data/aboutus/aboutus.json";
import workteam from "../data/aboutusteam/workteam.json";
import AboutUsImage from "../data/images/about/AboutUs.png";
import ProjectInfo from "../components/projectInfo";
const AboutUsPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page.content || []);

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO
                title="About"
                description="Woorg : The fastest growing performance marketing and advertising agency with clients around 5 continents in multiple industries. Connect now to boost your business."
                pathname="/aboutus"
            />
            <PageBreadcrumb
                pageContext={pageContext}
                backgroundImage={AboutUsImage}
                location={location}
                title="About Us"
            />
            {/* <AboutMoreArea data={content["about-more-section"]} /> */}
            <WhyChooseUsArea />

            {/* <TeamArea data={content["about-team-members-section"]} /> */}
            {/* <TestimonialArea data={content["happy-customer-section"]} /> */}
            <Herosection
                title="Here's What Makes Us Best"
                decription="How we help you set your brand goals and then bring to bear our expertise in Strategy, Advertising and Technology."
            />
            <AboutCard data={aboutus} />
            <Herosection
                title="About The Team"
                decription="Noone can whistle a symphony. It takes a whole orchestra to play it. 

                The perfect blend of creativity and technical wizardry. The best people formula for great marketing!s"
            />
            <AboutteamCard data={workteam} />
            <ProjectInfo />
        </Layout>
    );
};

AboutUsPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
    allServices: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export const query = graphql`
    query aboutPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    socials {
                        color
                        link
                        icon
                    }
                    ...Footer
                }
            }
        }
        page(title: { eq: "aboutUsPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
                section_title {
                    ...SectionTitle
                }
            }
        }
        allServices(
            limit: 6
            sort: { order: ASC, fields: rank }
            filter: { is_featured: { eq: true } }
        ) {
            nodes {
                ...Services
            }
        }
    }
`;

export default AboutUsPage;
