import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import Card from "../../../components/about/card";
import { ServicesSection } from "./style";
const AboutCard = ({ data }) => {
    return (
        <ServicesSection>
            <Container>
                <Row className="gx-5">
                    {/* {data?.items &&
                        data?.items.map((item, i) => {
                            return (
                                <Col lg={4} md={4} key={i}>
                                    <Card
                                        iconImage={item.images[0].src}
                                        lineImage={item.images[1].src}
                                        title={item.title}
                                        disText={item.excerpt}
                                        actionClass={item.className}
                                        outlineColor={item.outlineColor}
                                        slug={item.slug}
                                    />
                                </Col>
                            );
                        })} */}
                    {data.map((item, i) => {
                        return (
                            <Col lg={4} md={4} key={i}>
                                <Card
                                    iconImage={item.images[0].src}
                                    // lineImage={item.images[1].src}
                                    title={item.title}
                                    disText={item.excerpt}
                                    actionClass={item.className}
                                    outlineColor={item.outlineColor}
                                    slug={item.slug}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </ServicesSection>
    );
};
AboutCard.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                alt: PropTypes.string,
            })
        ),
    }),
};
export default AboutCard;
