import styled, { device, themeGet } from "@theme/utils";
export const PageTitleContent = styled.div`
    text-align: center;
    .breadcrumb__title {
        color: #fff;
        font-weight: 700;
        line-height: 1.545;
        font-size: 35px;
        font-family: ${themeGet("fonts.heading")};
        max-width: 708px;
        margin: auto;
}
        ${device.large} {
            font-size: 36px;
        
        }
    }
    
`;
export const Paragragh = styled.p`
    /* max-width: 616px; */
    margin: auto;
    font-size: 21px;
    line-height: 30px;
`;
