import styled, { device, keyframes } from "@theme/utils";

const move = keyframes`
    from, 20%, to {
        transform: translateY(0);
    }
    from, 53%, 80%, to {
        transform: translateY(-10px);
    }
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
 `;

export const ServicesSection = styled.section`
    padding: 60px 0;
    ${device.mobileview} {
        .css-1dvreho {
            display: block;
            margin-left: 85px;
        }
        & .container-max > .row {
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
`;
export const ChooseUsImage = styled.div`
    margin-bottom: 30px;
    text-align: center;
    animation: ${move} 0.8s infinite alternate;
`;
