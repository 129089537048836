import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PageTitleContent, Paragragh } from "./style";
const Herosection = ({ title, decription }) => {
    return (
        <Container>
            <PageTitleContent>
                <h2 className="TitleContent">{title}</h2>
                {decription && <Paragragh>{decription}</Paragragh>}
            </PageTitleContent>
        </Container>
    );
};

export default Herosection;
