import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import {
    ServiceCard,
    ServiceIcon,
    RotedAround,
    ServiceContent,
    Title,
    DisText,
    MainDiv,
} from "./style";
const Card = ({
    lineImage,
    iconImage,
    title,
    disText,
    actionClass,
    outlineColor,
    slug,
}) => {
    return (
        <ServiceCard>
            {/* <GatsbyImage className="line" image={imageLine} alt={title} /> */}
            <ServiceIcon>
                <img src={iconImage} alt={title} />
            </ServiceIcon>
            <ServiceContent>
                <Title> {title}</Title>
                <DisText>{disText}</DisText>
            </ServiceContent>
        </ServiceCard>
    );
};
Card.propTypes = {
    slug: PropTypes.string,
    lineImage: PropTypes.object,
    iconImage: PropTypes.object,
    title: PropTypes.string,
    disText: PropTypes.string,
    actionClass: PropTypes.string,
    outlineColor: PropTypes.string,
};
export default Card;
