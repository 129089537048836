import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import Card from "../../../components/about/cardteam";
import { ServicesSection, ChooseUsImage } from "./style";
// import { StaticImage } from "gatsby-plugin-image";
import About_The_Team from "../../../assets/svg/About_The_Team.svg";

const AboutteamCard = ({ data }) => {
    return (
        <ServicesSection>
            <Container className="container-max d-flex flex-column flex-lg-row justify-content-between align-items-center gx-5">
                <Row className="gx-5">
                    {/* {data?.items &&
                        data?.items.map((item, i) => {
                            return (
                                <Col lg={6} md={6} key={i}>
                                    <Card
                                        iconImage={item.images[0].src}
                                        lineImage={item.images[1].src}
                                        title={item.title}
                                        disText={item.excerpt}
                                        actionClass={item.className}
                                        outlineColor={item.outlineColor}
                                        slug={item.slug}
                                    />
                                </Col>
                            );
                        })} */}
                    {data.map((item, i) => {
                        return (
                            <Col lg={6} md={6} key={i}>
                                <Card
                                    iconImage={item.images[0].src}
                                    lineImage={item.images[1].src}
                                    title={item.title}
                                    disText={item.excerpt}
                                    actionClass={item.className}
                                    outlineColor={item.outlineColor}
                                    slug={item.slug}
                                />
                            </Col>
                        );
                    })}
                </Row>
                <Col lg={6}>
                    <ChooseUsImage>
                        <About_The_Team className="choose-us-img" />
                        {/* <StaticImage
                            src="/svg/About_The_Team.svg"
                            alt=""
                            className="choose-us-img"
                        /> */}
                    </ChooseUsImage>
                </Col>
            </Container>
        </ServicesSection>
    );
};
AboutteamCard.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                alt: PropTypes.string,
            })
        ),
    }),
};
export default AboutteamCard;
