import PropTypes from "prop-types";
// import { StaticImage } from "gatsby-plugin-image";
import { Row, Col, Container } from "react-bootstrap";
import ChooseUsList from "../../../components/choose-us-list";
import SectionTitleTwo from "../../../components/title-two";
import {
    WhyChooseUsSection,
    ChooseUsImage,
    WhyChooseUsOffset,
    ChooseListWrpa,
} from "./style";
import WhyChooseImg from "../../../assets/svg/Developing_Digital_Touch.svg";
import WhyChooseImg2 from "../../../assets/svg/The_Best_Marketing_Agency.svg";

const WhyChooseUsArea = ({ data }) => {
    return (
        <WhyChooseUsSection>
            {/* <StaticImage
                src="../../../data/images/bg/bg2.png"
                alt=""
                className="about-bg"
            /> */}
            <Container className="">
                <Row className="align-items-center">
                    <Col lg={6}>
                        <WhyChooseUsOffset>
                            <SectionTitleTwo
                                // subTitle={data?.section_title.subtitle}
                                title="Developing Digital Touch-points between your Brand and Customers"
                                shortDec="A cutting-edge, well-recognized digital advertising company devoted to elevating your business through revolutionary marketing solutions, custom strategies, channel expertise, and flawless execution. We’re building an environment that embraces trust and encourages vulnerability, which offers our crew the courage to push barriers and evolve."
                                shortDec1="As a digital agency, we combine creativity with technical know-how. In this way, we open up new ways for our clients to enter into direct communication dialogue with their target user group more effectively."
                            />
                        </WhyChooseUsOffset>
                    </Col>
                    <Col lg={6}>
                        <ChooseUsImage>
                            <WhyChooseImg className="choose-us-img" />
                            {/* <StaticImage
                                src="https://www.woorg.com/static/fc06a2caa3b0a0abeafb35c86bba2cdb/76d80/Bridging-The-Gaps-Between.webp"
                                alt=""
                                className="choose-us-img"
                            /> */}
                        </ChooseUsImage>
                    </Col>
                </Row>
            </Container>
            <Container className="">
                <Row className="align-items-center mobile-view-reverse">
                    <Col lg={6}>
                        <ChooseUsImage>
                            <WhyChooseImg2 className="choose-us-img" />
                            {/* <StaticImage
                                src="https://www.woorg.com/static/3e27bf6ae82cdfde4b90f32b5c89973c/2bd68/The-Best-Marketing-Agency.webp"
                                alt=""
                                className="choose-us-img"
                            /> */}
                        </ChooseUsImage>
                    </Col>
                    <Col lg={6}>
                        <WhyChooseUsOffset>
                            <SectionTitleTwo
                                // subTitle={data?.section_title.subtitle}
                                title="The Best Marketing Agency Across The Digital Spectrum!"
                                shortDec="We create digital experiences that users love and businesses need. A team of digital experts, storytellers, technologists, creators and curious minds, providing services in the areas of digital communication, advertising and technology."
                                shortDec1="We also have expertise in performance marketing and e-commerce developments, with clients across all the continents. Our other offerings are Search Engine Optimization and DevOps. ​We strive to make everything exceptional and achieve results that amaze us all."
                            />

                            {/* {data?.items &&
                                data?.items.map((item, i) => {
                                    return (
                                        <ChooseListWrpa key={i}>
                                            <ChooseUsList
                                                icon={item.images?.[0].src}
                                                title={item.headings[0].content}
                                                description={item.description}
                                            />
                                        </ChooseListWrpa>
                                    );
                                })} */}
                        </WhyChooseUsOffset>
                    </Col>
                </Row>
            </Container>
        </WhyChooseUsSection>
    );
};
WhyChooseUsArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            }),
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                alt: PropTypes.string,
            })
        ),
    }),
};
export default WhyChooseUsArea;
